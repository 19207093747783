import axios from "../../api/axios";
import { errorAlert, handleErrorMessage, successAlert } from "Utils";
import store from "../store";
import { authConstants, paymentConstants, ProfileConstants } from "./constant";
import { getUserAndProjectByAdminId } from "./project.actions";
import { auth } from "api/Auth";
import { signout } from "./auth.actions";
// import { addUserToTable } from './payment.actions';

export const getOrganizationProfile = (id) => {
  return async (dispatch, getState) => {
    dispatch({ type: ProfileConstants.ORGANIZATION_PROFILE_REQUEST });
    try {
      const res = await axios.get("/v1/projects/" + id + "/users");
      dispatch({
        type: ProfileConstants.ORGANIZATION_PROFILE_SUCCESS,
        payload: res.data.data,
      });
      // const someVal = getState().orgprofile.organizationProfile;
      // dispatch({
      //     type: paymentConstants.USER_EMPTY
      // })
      // dispatch(addUserToTable(someVal))
    } catch (error) {
      dispatch({
        type: ProfileConstants.ORGANIZATION_PROFILE_FAILURE,
        payload: error.message,
      });
    }
  };
};
export const deleteUserFromTheProject = (projectId, id, adminId) => {
  return async (dispatch) => {
    dispatch({ type: ProfileConstants.DELETE_USER_REQUEST });
    try {
      const res = await axios.delete(
        `/v1/projects/${projectId}/users/${id}/${adminId}`
      );
      // console.log(res)
      if (res.data.status === true) {
        successAlert(res.data.message);
        dispatch({
          type: ProfileConstants.DELETE_USER_SUCCESS,
          payload: id,
        });
        store.dispatch({
          type: paymentConstants.USER_EMPTY,
        });
        dispatch(getUserAndProjectByAdminId(adminId));
      } else {
        errorAlert(handleErrorMessage(res.data.message));
      }
    } catch (error) {
      errorAlert(handleErrorMessage(error));
      dispatch({
        type: ProfileConstants.DELETE_USER_FAILURE,
        payload: error.message,
      });
    }
  };
};
export const getProfileByUserId = (id) => {
  return async (dispatch) => {
    try {
      if (id) {
        const res = await axios.get("/v1/projects/{projectId}/users/" + id);
        dispatch({
          type: ProfileConstants.GET_PROFILE_SUCCESS,
          payload: res.data.data,
        });
      }
    } catch (error) {
      // console.log("Error occured");
    }
  };
};
export const avatarChange = (projectId, profileId, id, avatar) => {
  return async (dispatch) => {
    try {
      const res = await axios.put(
        `/v1/projects/{projectId}/users/${profileId}/avatar?avatar=${avatar}`
      );
      successAlert(res.data.message && "Avatar changed successfully");
      if (profileId === id) {
        const { kanbanId, projectId } = JSON.parse(
          window.localStorage.getItem("squser")
        );
        window.localStorage.removeItem("squser");
        const responseAvatar = { ...res?.data?.data };
        responseAvatar.kanbanId = kanbanId;
        responseAvatar.projectId = projectId;
        window.localStorage.setItem("squser", JSON.stringify(responseAvatar));
        const token = auth.getToken();
        const user = JSON.parse(window.localStorage.getItem("squser"));

        dispatch({
          type: ProfileConstants.PROFILE_AVATAR_REQUEST,
          payload: user,
        });
        if (user) {
          store.dispatch({
            type: authConstants.LOGIN_SUCCESS,
            payload: {
              token,
              user,
            },
          });
        }
      }
      dispatch(getOrganizationProfile(projectId));
      dispatch(getProfileByUserId(profileId));
    } catch (error) {
      // console.log(error);
    }
  };
};
export const updatePassword = (projectId, userData, profileId) => {
  return async (dispatch) => {
    try {
      const res = await axios.patch(
        `/v1/projects/${projectId}/users/update-password`,
        userData
      );
      successAlert(res.data.message && "Password changed successfully");
    } catch (error) {
      // console.log(error);
    }
  };
};
export const updateProfileName = (userName, id, projectId, profileId) => {
  return async (dispatch) => {
    dispatch({
      type: paymentConstants.SAVE_SUBSCRIPTIONS_STRIPE_REQUEST,
    });
    try {
      if (userName) {
        const response = await axios.patch(
          `/v1/projects/${projectId}/users/update/${profileId}?name=${userName?.name}`
        );
        successAlert(response.data.message && "Name changed successfully");
        if (profileId === id) {
          const { kanbanId, projectId } = JSON.parse(
            window.localStorage.getItem("squser")
          );
          window.localStorage.removeItem("squser");
          const responseProfile = { ...response?.data?.data };
          responseProfile.kanbanId = kanbanId;
          responseProfile.projectId = projectId;
          window.localStorage.setItem(
            "squser",
            JSON.stringify(responseProfile)
          );
          const user = JSON.parse(window.localStorage.getItem("squser"));
          const token = auth.getToken();
          dispatch({
            type: ProfileConstants.PROFILE_NAME,
            payload: user,
          });
          if (user) {
            store.dispatch({
              type: authConstants.LOGIN_SUCCESS,
              payload: {
                token,
                user,
              },
            });
          }
        }
      }
      dispatch(getProfileByUserId(profileId));
      dispatch(getOrganizationProfile(projectId));
    } catch (error) {
      if (error && error.response && error.response.status === 401) {
        return;
      } else {
        errorAlert(handleErrorMessage(error));
      }
    }
  };
};

export const deleteProfile = (userId, projectId) => {
  return async (dispatch) => {
    dispatch({
      type: ProfileConstants.DELETE_PROFILE_REQUEST,
    });
    try {
      if (userId && projectId) {
        const response = await axios.post(
          `/v1/projects/${projectId}/users/deactivate/${userId}`
        );
        if (response && response.status) {
          dispatch({
            type: ProfileConstants.DELETE_PROFILE_SUCCESS,
          });

          successAlert("Profile Has Deleted Successfully");
        }
      }
    } catch (error) {
      if (error) {
        dispatch({
          type: ProfileConstants.DELETE_PROFILE_FAILED,
        });
      }
    }
  };
};

export const authConstants = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
  CLEAR__ERROR: "CLEAR__ERROR",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  REMOVE_SUCCESS_FROM_FORGET_PASS: "REMOVE_SUCCESS_FROM_FORGET_PASS",
  GET_USER_INFO_LOGGED_REQUEST: "GET_USER_INFO_LOGGED_REQUEST",
  GET_USER_INFO_LOGGED_SUCCESS: "GET_USER_INFO_LOGGED_SUCCESS",
  GET_USER_INFO_LOGGED_FAILED: "GET_USER_INFO_LOGGED_FAILED",
};

export const userConstants = {
  USER_REGISTER_REQUEST: "USER_REGISTER_REQUEST",
  USER_REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
  USER_REGISTER_FAILURE: "USER_REGISTER_FAILURE",
  ACTIVATION_REQUEST: "ACTIVATION_REQUEST",
  ACTIVATION__SUCCESS: "ACTIVATION__SUCCESS",
  ACTIVATION_FAILURE: "ACTIVATION_FAILURE",
  ACTIVATION_USER_REQUEST: "ACTIVATION_USER_REQUEST",
  ACTIVATION_USER__SUCCESS: "ACTIVATION_USER__SUCCESS",
  ACTIVATION_USER_FAILURE: "ACTIVATION_USER_FAILURE",
  RESEND_ACTIVATION: "RESEND_ACTIVATION",
  REMOVE_SUCCESS_FROM_SIGNUP: "REMOVE_SUCCESS_FROM_SIGNUP",
};

export const paymentConstants = {
  USER_ADD: "USER_ADD",
  USER_UPDATE: "USER_UPDATE",
  USER_DELETE: "USER_DELETE",
  USER_EMPTY: "USER_EMPTY",
  DISABLE_FALSE: "DISABLE_FALSE",
  SAVE_SUBSCRIPTIONS_REQUEST: "SAVE_SUBSCRIPTIONS_REQUEST",
  SAVE_SUBSCRIPTIONS_SUCCESS: "SAVE_SUBSCRIPTIONS_SUCCESS",
  SAVE_SUBSCRIPTIONS_STRIPE_SUCCESS: "SAVE_SUBSCRIPTIONS_STRIPE_SUCCESS",
  STRIPE_SUCCESS_MODAL_CLOSE: "STRIPE_SUCCESS_MODAL_CLOSE",
  SAVE_SUBSCRIPTIONS_STRIPE_FAILURE: "SAVE_SUBSCRIPTIONS_STRIPE_FAILURE",
  SAVE_SUBSCRIPTIONS_STRIPE_REQUEST: "SAVE_SUBSCRIPTIONS_STRIPE_REQUEST",
  SAVE_SUBSCRIPTIONS_FAILURE: "SAVE_SUBSCRIPTIONS_FAILURE",
  GET_ALL_SUBSCRIPTIONS: "GET_ALL_SUBSCRIPTIONS",
  GET_SINGLE_SUBSCRIPTION: "GET_SINGLE_SUBSCRIPTION",
};

export const ProfileConstants = {
  PROFILE_REQUEST: "PROFILE_REQUEST",
  PROFILE_AVATAR_REQUEST: "PROFILE_AVATAR_REQUEST",
  PROFILE_AVATAR_SUCCESS: "PROFILE_AVATAR_SUCCESS",
  PROFILE_SUCCESS: "PROFILE_SUCCESS",
  GET_PROFILE_REQUEST: "GET_PROFILE_REQUEST",
  DELETE_USER_REQUEST: "DELETE_USER_REQUEST",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE: "DELETE_USER_FAILURE",
  GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
  GET_PROFILE_FAILURE: "GET_PROFILE_FAILURE",
  ORGANIZATION_PROFILE_SUCCESS: "ORGANIZATION_PROFILE_SUCCESS",
  ORGANIZATION_PROFILE_REQUEST: "ORGANIZATION_PROFILE_REQUEST",
  ORGANIZATION_PROFILE_FAILURE: "ORGANIZATION_PROFILE_FAILURE",
  USER_PASSWORD_SUCCESS: "USER_PASSWORD_SUCCESS",
  USER_PASSWORD_REQUEST: "USER_PASSWORD_REQUEST",
  USER_PASSWORD_FAILURE: "USER_PASSWORD_FAILURE",
  PROFILE_NAME: "PROFILE_NAME",
  DELETE_PROFILE_REQUEST: "DELETE_PROFILE_REQUEST",
  DELETE_PROFILE_SUCCESS: "DELETE_PROFILE_SUCCESS",
  DELETE_PROFILE_FAILED: "DELETE_PROFILE_FAILED",
};
export const ProjectConstants = {
  PROJECT_CREATE_REQUEST: "PROJECT_CREATE_REQUEST",
  PROJECT_CREATE_SUCCESS: "PROJECT_CREATE_SUCCESS",
  PROJECT_CREATE_FAILURE: "PROJECT_CREATE_FAILURE",
  PROJECT_UPDATE: "PROJECT_UPDATE",
  PROJECT_GET: "PROJECT_GET",
  ALL_PROJECTS_GET: "ALL_PROJECTS_GET",
  CLONE_PROJECT_REQUEST: "CLONE_PROJECT_REQUEST",
  CLONE_PROJECT_SUCCESS: "CLONE_PROJECT_SUCCESS",
  CLONE_RPOJECT_FAILED: "CLONE_RPOJECT_FAILED",
  ASSIGNE_PROJECT_USERS_REQUEST: "ASSIGNE_PROJECT_USERS_REQUEST",
  ASSIGNE_PROJECT_USERS_SUCCESS: "ASSIGNE_PROJECT_USERS_SUCCESS",
  ASSIGNE_PROJECT_USERS_FAILURE: "ASSIGNE_PROJECT_USERS_FAILURE",
  //assigned admin
  PROJECT_OWNERSHIP_REQUEST: "PROJECT_OWNERSHIP_REQUEST",
  PROJECT_OWNERSHIP_SUCCESS: "PROJECT_OWNERSHIP_SUCCESS",
  PROJECT_OWNERSHIP_FAILURE: "PROJECT_OWNERSHIP_FAILURE",
  GET_PROJECT_USER_BY_PROJECTID_REQUEST:
    "GET_PROJECT_USER_BY_PROJECTID_REQUEST",
  GET_PROJECT_USER_BY_PROJECTID_SUCCESS:
    "GET_PROJECT_USER_BY_PROJECTID_SUCCESS",
  GET_PROJECT_USER_BY_PROJECTID_FAILURE:
    "GET_PROJECT_USER_BY_PROJECTID_FAILURE",
  GET_PROJECT_USER_BY_ADMINID_REQUEST: "GET_PROJECT_USER_BY_ADMINID_REQUEST",
  GET_PROJECT_USER_BY_ADMINID_SUCCESS: "GET_PROJECT_USER_BY_ADMINID_SUCCESS",
  GET_PROJECT_USER_BY_ADMINID_FAILURE: "GET_PROJECT_USER_BY_ADMINID_FAILURE",
  DELETE_PROJECT_REQUEST: "DELETE_PROJECT_REQUEST",
  DELETE_PROJECT_SUCCESS: "DELETE_PROJECT_SUCCESS",
  DELETE_PROJECT_FAILED: "DELETE_PROJECT_FAILED",
  DOWNLOAD_TIMESHEET: "DOWNLOAD_TIMESHEET",
  DOWNLOAD_TIMESHEET_FAILED: "DOWNLOAD_TIMESHEET_FAILED",
};

export const diagramConstant = {
  ADDEDGE: "ADDEDGE",
  DELETE_CSV: "DELETE_CSV",
  EMPTY_DIAGRAM: "EMPTY_DIAGRAM",
  ADD_VALUE_STREAM: "ADD_VALUE_STREAM",
  DELETE_VALUE_STREAM: "DELETE_VALUE_STREAM",
  UPDATE_VALUE_STREAM: "UPDATE_VALUE_STREAM",
  GET_VALUE_STREAM: "GET_VALUE_STREAM",
  ADD_NEW_PERSONA: "ADD_NEW_PERSONA",
  GET_PERSONA_DATA: "GET_PERSONA_DATA",
  UPDATE_PERSONA_DATA: "UPDATE_PERSONA_DATA",
  ADD_NEW_ELEMENT: "ADD_NEW_ELEMENT",
  LOADDATA: "LOADDATA",
  LOADALLDATA: "LOADALLDATA",
  LOAD_TABLE_DATA_FROM_BACKEND: "LOAD_TABLE_DATA_FROM_BACKEND",
  ADD_NEW_FORM_NODE_DATA: "ADD_NEW_FORM_NODE_DATA",
  CLOSE_FORM_NODE_DATA: "CLOSE_FORM_NODE_DATA",
  UPDATE_NEW_FORM_NODE_DATA: "UPDATE_NEW_FORM_NODE_DATA",
  DELETE_NEW_FORM_NODE_DATA: "DELETE_NEW_FORM_NODE_DATA",
  FOR_CSV_WITH_VALUE_STREAM: "FOR_CSV_WITH_VALUE_STREAM",
  CREATE_NEW_WORKFLOW: "CREATE_NEW_WORKFLOW",
  UPDATE_NEW_WORKFLOW: "UPDATE_NEW_WORKFLOW",
  DELETE_A_WORKFLOW: "DELETE_A_WORKFLOW",
  CLONE_A_WORKFLOW: "CLONE_A_WORKFLOW",
  GET_ALL_WORKFLOW: "GET_ALL_WORKFLOW",
  ADD_VALUE_STREAM_FLOW: "ADD_VALUE_STREAM_FLOW",
  GET_CONTENTS_BY_PROJECTID: "GET_CONTENTS_BY_PROJECTID",
  GET_ALL_VALUE_STREAM: "GET_ALL_VALUE_STREAM",
  UPLOAD_FILE_REQUEST: "UPLOAD_FILE_REQUEST",
  UPLOAD_FILE_SUCCESS: "UPLOAD_FILE_SUCCESS",
  EMPTY_FILES: "EMPTY_FILES",
  VALUE_STREAM_DRAG: "VALUE_STREAM_DRAG",
  GET_WORKFLOW_REQUEST: "GET_WORKFLOW_REQUEST",
  GET_ALL_WORKFLOW_DETAILS: "GET_ALL_WORKFLOW_DETAILS",
  EMPTY_ALL_WORKFLOW_DETAILS: "EMPTY_ALL_WORKFLOW_DETAILS",
};
export const kanbanConstant = {
  GENERATE_KANBAN: "GENERATE_KANBAN",
  GET_ALL_LANE_REQUEST: "GET_ALL_LANE_REQUEST",
  GET_ALL_LANE_SUCCESS: "GET_ALL_LANE_SUCCESS",
  GET_ALL_KANBAN_SUCCESS: "GET_ALL_KANBAN_SUCCESS",
  GET_SINGLE_KANBAN_SUCCESS: "GET_SINGLE_KANBAN_SUCCESS",
  DELETE_SINGLE_KANBAN_SUCCESS: "DELETE_SINGLE_KANBAN_SUCCESS",
  UPDATE_SINGLE_KANBAN_SUCCESS: "UPDATE_SINGLE_KANBAN_SUCCESS",
  UPDATE_LANE_DRAG: "UPDATE_LANE_DRAG",
  ADD_NEW_KANBAN_EMPTY: "ADD_NEW_KANBAN_EMPTY",
  UPDATE_KANBAN_CARD: "UPDATE_KANBAN_CARD",
  ADD_KANBAN_LANE: "ADD_KANBAN_LANE",
  UPDATE_KANBAN_LANE: "UPDATE_KANBAN_LANE",
  DELETE_KANBAN_LANE: "DELETE_KANBAN_LANE",
  DOCUMENT_DELETE_REQUEST: "DOCUMENT_DELETE_REQUEST",
  DOCUMENT_DELETE_SUCCESS: "DOCUMENT_DELETE_SUCCESS",
  ADD_ESTIMATE_TIME: "ADD_ESTIMATE_TIME",
  GET_USERS_LANE_SUCCESS: "GET_USERS_LANE_SUCCESS",
  IMAGE_PREVIEW: "IMAGE_PREVIEW",
  MAKING_EMPTY: "MAKING_EMPTY",
  GET_VELOCITY_REPORT: "GET_VELOCITY_REPORT",
  GET_ALL_CARDS_BY_PROJECT_ID: "GET_ALL_CARDS_BY_PROJECT_ID",
  DOWNLOAD_JASPER_REPORTS: "DOWNLOAD_JASPER_REPORTS",
  GET_KANBAN_CATEGORY: "GET_KANBAN_CATEGORY",
  GET_KANBAN_CATEGORY_ERROR: "GET_KANBAN_CATEGORY_ERROR",
  CREATE_KANBAN_CATEGORY: "CREATE_KANBAN_CATEGORY",
  CREATE_KANBAN_CATEGORY_ERROR: "CREATE_KANBAN_CATEGORY_ERROR",
  DELETE_KANBAN_CATEGORY: "DELETE_KANBAN_CATEGORY",
  CHANGE_KANBAN_CATEGORY: "CHANGE_KANBAN_CATEGORY",
  GET_RELEASE_CARD_COUNTINGS: "GET_RELEASE_CARD_COUNTINGS",
};
export const riskAndIssueConstant = {
  GET_ALL_RISK_AND_LANE_SUCCESS: "GET_ALL_RISK_AND_LANE_SUCCESS",
  GET_SINGLE_ATTACHMENTS_DATA_BY_CARD: "GET_SINGLE_ATTACHMENTS_DATA_BY_CARD",
  GET_SINGLE_TREATMENTS_CARD: "GET_SINGLE_TREATMENTS_CARD",
  REMOVE_SINGLE_TREATMENTS_CARD: "REMOVE_SINGLE_TREATMENTS_CARD",
  REMOVE_SINGLE_ATTACHMENTS_DATA_BY_CARD:
    "REMOVE_SINGLE_ATTACHMENTS_DATA_BY_CARD",
  GET_ALL__RISK_AND_ISSUE_CARD_SUCCESS: "GET_ALL__RISK_AND_ISSUE_CARD_SUCCESS",
  GET_ALL_RISK_KANBAN_SUCCESS: "GET_ALL_RISK_KANBAN_SUCCESS",
  GET_SINGLE_KANBAN_SUCCESS: "GET_SINGLE_KANBAN_SUCCESS",
  GET_SINGLE_CARD_SUCCESS: "GET_SINGLE_CARD_SUCCESS",
  UPDATE_SINGLE_KANBAN_SUCCESS: "UPDATE_SINGLE_KANBAN_SUCCESS",
  UPDATE_LANE_DRAG: "UPDATE_LANE_DRAG",
  ADD_NEW_KANBAN_EMPTY: "ADD_NEW_KANBAN_EMPTY",
  UPDATE_KANBAN_CARD: "UPDATE_KANBAN_CARD",
  ADD_KANBAN_LANE: "ADD_KANBAN_LANE",
  UPDATE_KANBAN_LANE: "UPDATE_KANBAN_LANE",
  DELETE_KANBAN_LANE: "DELETE_KANBAN_LANE",
  DOCUMENT_DELETE_REQUEST: "DOCUMENT_DELETE_REQUEST",
  DOCUMENT_DELETE_SUCCESS: "DOCUMENT_DELETE_SUCCESS",
  ADD_ESTIMATE_TIME: "ADD_ESTIMATE_TIME",
  GET_USERS_LANE_SUCCESS: "GET_USERS_LANE_SUCCESS",
  GET_LANE_COUNT: "GET_LANE_COUNT",
  GET_SINGLE_NOT_DELETABLE_ID: "GET_SINGLE_NOT_DELETABLE_ID",
};
export const journeyMapConstant = {
  GET_ALL_RISK_AND_LANE_SUCCESS: "GET_ALL_RISK_AND_LANE_SUCCESS",
  GET_SINGLE_ATTACHMENTS_DATA_BY_CARD: "GET_SINGLE_ATTACHMENTS_DATA_BY_CARD",
  GET_SINGLE_TREATMENTS_CARD: "GET_SINGLE_TREATMENTS_CARD",
  REMOVE_SINGLE_TREATMENTS_CARD: "REMOVE_SINGLE_TREATMENTS_CARD",
  REMOVE_SINGLE_ATTACHMENTS_DATA_BY_CARD:
    "REMOVE_SINGLE_ATTACHMENTS_DATA_BY_CARD",
  GET_ALL__RISK_AND_ISSUE_CARD_SUCCESS: "GET_ALL__RISK_AND_ISSUE_CARD_SUCCESS",
  GET_ALL_RISK_KANBAN_SUCCESS: "GET_ALL_RISK_KANBAN_SUCCESS",
  GET_SINGLE_KANBAN_SUCCESS: "GET_SINGLE_KANBAN_SUCCESS",
  GET_SINGLE_CARD_SUCCESS: "GET_SINGLE_CARD_SUCCESS",
  UPDATE_SINGLE_KANBAN_SUCCESS: "UPDATE_SINGLE_KANBAN_SUCCESS",
  UPDATE_LANE_DRAG: "UPDATE_LANE_DRAG",
  ADD_NEW_KANBAN_EMPTY: "ADD_NEW_KANBAN_EMPTY",
  UPDATE_KANBAN_CARD: "UPDATE_KANBAN_CARD",
  ADD_KANBAN_LANE: "ADD_KANBAN_LANE",
  UPDATE_KANBAN_LANE: "UPDATE_KANBAN_LANE",
  DELETE_KANBAN_LANE: "DELETE_KANBAN_LANE",
  DOCUMENT_DELETE_REQUEST: "DOCUMENT_DELETE_REQUEST",
  DOCUMENT_DELETE_SUCCESS: "DOCUMENT_DELETE_SUCCESS",
  ADD_ESTIMATE_TIME: "ADD_ESTIMATE_TIME",
  GET_USERS_LANE_SUCCESS: "GET_USERS_LANE_SUCCESS",
  JOURNEYMAP_DOWNLOAD: "JOURNEYMAP_DOWNLOAD",
  JOURNEYMAP_DOWNLOAD_FAILED: "JOURNEYMAP_DOWNLOAD_FAILED",
  GET_ALL_JOURNEY_DETAILS: "GET_ALL_JOURNEY_DETAILS",
};

export const websocketConstant = {
  REF_CONNECT: "REF_CONNECT",
  SENDMESSAGE: "SENDMESSAGE",
  ONCONNECT: "ONCONNECT",
  DISCONNECT: "DISCONNECT",
};

export const logHour = {
  FETCH_LOG_HOURS_REQUEST: "FETCH_LOG_HOURS_REQUEST",
  FETCH_LOG_HOURS_SUCCESS: "FETCH_LOG_HOURS_SUCCESS",
  FETCH_LOG_HOURS_FAILED: "FETCH_LOG_HOURS_FAILED",
  ADD_LOG_HOUR: "ADD_LOG_HOUR",
  FETCH_LOG_HOUR_REQUEST: "FETCH_LOG_HOUR_REQUEST",
  FETCH_LOG_HOUR_SUCCESS: "FETCH_LOG_HOUR_SUCCESS",
  FETCH_LOG_HOUR_FAILED: "FETCH_LOG_HOUR_FAILED",
  UPDATE_LOG_HOUR_SUCCESS: "UPDATE_LOG_HOUR_SUCCESS",
  UPDATE_LOG_HOUR_FAILED: "UPDATE_LOG_HOUR_FAILED",
  DELETE_LOG_HOUR_SUCCESS: "DELETE_LOG_HOUR_SUCCESS",
  DELETE_LOG_HOUR_FAILED: "DELETE_LOG_HOUR_FAILED",
  FIND_LOG_HOUR_BYCARD_REQUEST: "FIND_LOG_HOUR_BYCARD_REQUEST",
  FIND_LOG_HOUR_BYCARD_SUCCESS: "FIND_LOG_HOUR_BYCARD_SUCCESS",
  FIND_LOG_HOUR_BYCARD_FAILED: "FIND_LOG_HOUR_BYCARD_FAILED",
};

export const logEnable = {
  FETCH_ENABLE_LOGHOUR_REQUEST: "FETCH_ENABLE_LOGHOUR_REQUEST",
  FETCH_ENABLE_LOGHOUR_SUCCESS: "FETCH_ENABLE_LOGHOUR_SUCCESS",
  FETCH_ENABLE_LOGHOUR_FAILED: "FETCH_ENABLE_LOGHOUR_FAILED",
  UPDATE_LOGHOUR_ENABLE_SUCCESS: "UPDATE_LOGHOUR_ENABLE_SUCCESS",
  UPDATE_LOGHOUR_ENABLE_FAILED: "UPDATE_LOGHOUR_ENABLE_FAILED",
};

export const problemStatement = {
  CREATE_PROBLEM_STATEMENT_REQUEST: "CREATE_PROBLEM_STATEMENT_REQUEST",
  CREATE_PROBLEM_STATEMENT_SUCCESS: "CREATE_PROBLEM_STATEMENT_SUCCESS",
  CREATE_PROBLEM_STATEMENT_FAILED: "CREATE_PROBLEM_STATEMENT_FAILED",
  GET_PROBLEM_STATEMENT_REQUEST: "GET_PROBLEM_STATEMENT_REQUEST",
  GET_PROBLEM_STATEMENT_SUCCESS: "GET_PROBLEM_STATEMENT_SUCCESS",
  GET_PROBLEM_STATEMENT_FAILED: "GET_PROBLEM_STATEMENT_FAILED",
  UPDATE_PROBLEM_STATEMENT_REQUEST: "UPDATE_PROBLEM_STATEMENT_REQUEST",
  UPDATE_PROBLEM_STATEMENT_SUCCESS: "UPDATE_PROBLEM_STATEMENT_SUCCESS",
  UPDATE_PROBLEM_STATEMENT_FAILED: "UPDATE_PROBLEM_STATEMENT_FAILED",
};

export const personas = {
  CREATE_PERSONAS_REQUEST: "CREATE_PERSONAS_REQUEST",
  CREATE_PERSONAS_SUCCESS: "CREATE_PERSONAS_SUCCESS",
  CREATE_PESONAS_FAILED: "CREATE_PESONAS_FAILED",
  GET_PERSONAS_REQUEST: "GET_PERSONAS_REQUEST",
  GET_PERSONAS_SUCCESS: "GET_PERSONAS_SUCCESS",
  GET_PERSONAS_FAILED: "GET_PERSONAS_FAILED",
  GET_SINGLEP_PERSONAS_REQUEST: "GET_SINGLEP_PERSONAS_REQUEST",
  GET_SINGLEP_PERSONAS_SUCCESS: "GET_SINGLEP_PERSONAS_SUCCESS",
  GET_SINGLEP_PERSONAS_FAILED: "GET_SINGLEP_PERSONAS_FAILED",
  UPDATE_PERSONAS_REQUEST: "UPDATE_PERSONAS_REQUEST",
  UPDATE_PERSONAS_SUCCESS: "UPDATE_PERSONAS_SUCCESS",
  UPDATE_PERSONAS_FAILED: "UPDATE_PERSONAS_FAILED",
  CREATE_PERSONAS_ITEM_LIST_REQUEST: "CREATE_PERSONAS_ITEM_LIST_REQUEST",
  CREATE_PERSONAS_ITEM_LIST_SUCCESS: "CREATE_PERSONAS_ITEM_LIST_SUCCESS",
  CREATE_PERSONAS_ITEM_LIST_FAILED: "CREATE_PERSONAS_ITEM_LIST_FAILED",
  GET_PERSONAS_ITEM_LIST_REQUEST: "GET_PERSONAS_ITEM_LIST_REQUEST",
  GET_PERSONAS_ITEM_LIST_SUCCESS: "GET_PERSONAS_ITEM_LIST_SUCCESS",
  GET_PERSONAS_ITEM_LIST_FAILED: "GET_PERSONAS_ITEM_LIST_FAILED",
  UPDATE_PERSONAS_ITEM_LIST_REQUEST: "UPDATE_PERSONAS_ITEM_LIST_REQUEST",
  UPDATE_PERSONAS_ITEM_LIST_SUCCESS: "UPDATE_PERSONAS_ITEM_LIST_SUCCESS",
  UPDATE_PERSONAS_ITEM_LIST_FAILED: "UPDATE_PERSONAS_ITEM_LIST_FAILED",
  DELETE_SINGLE_PERSONA_REQUEST: "DELETE_SINGLE_PERSONA_REQUEST",
  DELETE_SINGLE_PERSONA_SUCCESS: "DELETE_SINGLE_PERSONA_SUCCESS",
  DELETE_SINGLE_PERSONA_FAILED: "DELETE_SINGLE_PERSONA_FAILED",
  GET_ONLY_ID_AND_NAME: "GET_ONLY_ID_AND_NAME",
  EMPTY_ALL_PERSONA_DETAILS: "EMPTY_ALL_PERSONA_DETAILS",
};

export const heartBeat = {
  GET_HEART_BEAT_API_REQUEST: "GET_HEART_BEAT_API_REQUEST",
  GET_HEART_BEAT_API_SUCCESS: "GET_HEART_BEAT_API_SUCCESS",
  GET_HEART_BEAT_API_FAILED: "GET_HEART_BEAT_API_FAILED",
};

export const profileConfig = {
  GET_ALL_PROFILE_CONFIG_REQUEST: "GET_ALL_PROFILE_CONFIG_REQUEST",
  GET_ALL_PROFILE_CONFIG_SUCCESS: "GET_ALL_PROFILE_CONFIG_SUCCESS",
  GET_ALL_HOME_REQUEST: "GET_ALL_HOME_REQUEST",
  GET_ALL_HOME_SUCCESS: "GET_ALL_HOME_SUCCESS",
  GET_ALL_PROFILE_CONFIG_ERROR: "GET_ALL_PROFILE_CONFIG_ERROR",
  GET_SINGLE_PROFILE_CONFIG_REQUEST: "GET_SINGLE_PROFILE_CONFIG_REQUEST",
  GET_SINGLE_PROFILE_CONFIG_SUCCESS: "GET_SINGLE_PROFILE_CONFIG_SUCCESS",
  GET_SINGLE_PROFILE_CONFIG_FAILED: "GET_SINGLE_PROFILE_CONFIG_FAILED",
  SAVE_CONFIG: "SAVE_CONFIG",
  UPDATE_CONFIG: "UPDATE_CONFIG",
  GET_CHECKED_REQUEST: "GET_CHECKED_REQUEST",
  GET_CHECKED_SUCCESS: "GET_CHECKED_SUCCESS",
};

export const cardconfig = {
  GET_ALL_PROFILE_CONFIG_REQUEST_CARD: "GET_ALL_PROFILE_CONFIG_REQUEST_CARD",
  GET_ALL_PROFILE_CONFIG_SUCCESS_CARD: "GET_ALL_PROFILE_CONFIG_SUCCESS_CARD",
  GET_ALL_HOME_REQUEST_CARD: "GET_ALL_HOME_REQUEST_CARD",
  GET_ALL_HOME_SUCCESS_CARD: "GET_ALL_HOME_SUCCESS_CARD",
  GET_ALL_PROFILE_CONFIG_ERROR_CARD: "GET_ALL_PROFILE_CONFIG_ERROR_CARD",
  GET_SINGLE_PROFILE_CONFIG_REQUEST_CARD:
    "GET_SINGLE_PROFILE_CONFIG_REQUEST_CARD",
  GET_SINGLE_PROFILE_CONFIG_SUCCESS_CARD:
    "GET_SINGLE_PROFILE_CONFIG_SUCCESS_CARD",
  GET_SINGLE_PROFILE_CONFIG_FAILED_CARD:
    "GET_SINGLE_PROFILE_CONFIG_FAILED_CARD",
  SAVE_CONFIG_CARD: "SAVE_CONFIG_CARD",
  UPDATE_CONFIG_CARD: "UPDATE_CONFIG_CARD",
  GET_CHECKED_REQUEST_CARD: "GET_CHECKED_REQUEST_CARD",
  GET_CHECKED_SUCCESS_CARD: "GET_CHECKED_SUCCESS_CARD",
};
